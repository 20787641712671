<template>
  <div class="invoiceHeadInfoPage">
    <div class="top">
      <el-button type="primary" size="medium" @click="save('vehicleForm')">保存</el-button>
    </div>
    <!-- 表单区域 -->
    <el-form :model="vehicleForm" :rules="vehicleRule" ref="vehicleForm" label-width="150px">
      <el-form-item label="发票抬头企业全名" prop="invoiceTitleName">
        <el-input v-model="vehicleForm.invoiceTitleName" placeholder="请输入发票企业抬头"></el-input>
      </el-form-item>
      <el-form-item label="纳税人识别号" prop="invoiceTitleCreditCode">
        <el-input v-model="vehicleForm.invoiceTitleCreditCode" placeholder="请输入纳税人识别号"></el-input>
      </el-form-item>
      <el-form-item label="银行账户" prop="invoiceTitleBankerNumber">
        <el-input v-model="vehicleForm.invoiceTitleBankerNumber" placeholder="请输入银行基本账号"></el-input>
      </el-form-item>
      <el-form-item label="银行账户开户行" prop="invoiceTitleBankerName">
        <el-input v-model="vehicleForm.invoiceTitleBankerName" placeholder="请输入银行账户开户行"></el-input>
      </el-form-item>
      <el-form-item label="企业联系地址" prop="invoiceTitleAddress">
        <el-input v-model="vehicleForm.invoiceTitleAddress" placeholder="请输入企业联系地址"></el-input>
      </el-form-item>
      <el-form-item label="企业联系电话" prop="invoiceTitlePhone">
        <el-input v-model="vehicleForm.invoiceTitlePhone" placeholder="请输入企业联系电话"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { saveInvoiceHead, getInvoiceHead } from '@/api/goodsOwner/goodsOwnerManage/invoiceHeadInfo'
import verify from '@/utils/verify.js'
export default {
  data() {
    return {
      vehicleForm: {
        invoiceTitleName: '', //发票抬头企业全名
        invoiceTitleCreditCode: '', //纳税人识别号
        invoiceTitleBankerNumber: '', //银行基本账号
        invoiceTitleBankerName: '', //银行账户开户行
        invoiceTitleAddress: '', //企业联系地址
        invoiceTitlePhone: '', //企业联系电话
      },
      vehicleRule: {
        invoiceTitleName: [{ required: true, message: '请输入发票抬头企业全名', trigger: 'change' }],
        invoiceTitleCreditCode: [{ required: true, message: '请输入纳税人识别号', trigger: 'change' }],
        invoiceTitleBankerNumber: [{ required: true, message: '请输入银行基本账号', trigger: 'change' }],
        invoiceTitleBankerName: [{ required: true, message: '请输入银行账户开户行', trigger: 'change' }],
        invoiceTitleAddress: [{ required: true, message: '请输入企业联系地址', trigger: 'change' }],
        invoiceTitlePhone: [{ required: true, message: '请输入企业联系电话', trigger: 'change' }],
      },
    }
  },
  methods: {
    ...mapMutations(["setFullInfo"]),
    //保存
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            userID: 0,
            invoiceTitleCreditCode: this.vehicleForm.invoiceTitleCreditCode,
            invoiceTitleName: this.vehicleForm.invoiceTitleName,
            invoiceTitleAddress: this.vehicleForm.invoiceTitleAddress,
            invoiceTitlePhone: this.vehicleForm.invoiceTitlePhone,
            invoiceTitleBankerNumber: this.vehicleForm.invoiceTitleBankerNumber,
            invoiceTitleBankerName: this.vehicleForm.invoiceTitleBankerName,
            isDefault: 0
          }
          saveInvoiceHead(params).then(res => {
            this.setFullInfo(3)
          })
        }
      })
    },
    //获取发票信息
    getInvoiceHead() {
      getInvoiceHead().then(res => {
        if(res.invoiceTiltle){
          this.vehicleForm.invoiceTitleName = res.invoiceTiltle.InvoiceTitleName
          this.vehicleForm.invoiceTitleCreditCode = res.invoiceTiltle.InvoiceTitleCreditCode
          this.vehicleForm.invoiceTitleBankerNumber = res.invoiceTiltle.InvoiceTitleBankerNumber
          this.vehicleForm.invoiceTitleBankerName = res.invoiceTiltle.InvoiceTitleBankerName
          this.vehicleForm.invoiceTitleAddress = res.invoiceTiltle.InvoiceTitleAddress
          this.vehicleForm.invoiceTitlePhone = res.invoiceTiltle.InvoiceTitlePhone
          this.setFullInfo(3)
        }
      })
    },
  },
  created() {
    this.getInvoiceHead()
  }
}
</script>

<style lang="scss" scoped>
.invoiceHeadInfoPage {
  .top {
    margin-bottom: 30px;
  }
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 40%;
    }
    .el-date-editor,
    .el-select {
      width: 100%;
    }
  }
}
</style>