import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//保存发票抬头
export const saveInvoiceHead = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/AddInvoiceTitle`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询发票抬头
export const getInvoiceHead = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/GetInvoiceTitle`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}